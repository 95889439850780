import { useCommonContext } from "@/contexts/CommonContext"
import { addItemToCart, cartCheckout, getCartItems, removeCartItem, updateCartItemQty } from "@/server/server-actions"
import { useEffect, useTransition } from "react"
import { useToast } from "./use-toast"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { CustomImageContextType } from "@/types/contextTypes"
import { useSession } from "next-auth/react"
import { ToastAction } from "@/components/ui/toast"
import { Properties } from "@/utils/properties"


export const useCart = () => {
    const [loading, startTransition] = useTransition()
    const [commonCtx, setCommonCtx] = useCommonContext()
    const { toast } = useToast()
    const router = useRouter()
    const session = useSession()
    const searchParams = useSearchParams()
    const pathName = usePathname()
    const isCustomizable = pathName.startsWith(Properties.routes.PRODUCT_LIST)

    useEffect(() => {
        console.log('useCart', session.status, searchParams.get('cart'))
        if (session.status === 'authenticated' && searchParams.get('cart') == 'open') {
            setCommonCtx(prev => ({ ...prev, cartDrawerOpen: true }))
            const newParams = new URLSearchParams(searchParams)
            newParams.delete('cart')
            router.replace('?' + newParams.toString())
        }
    }, [session.status])

    const onToastLoginButtonClick = () => {
        setCommonCtx(prev => ({ ...prev, loginModalOpen: true }))
    }

    const addToCart = async (productID: number, customImgCtx: CustomImageContextType, qty: number, selectedSize: number, selectedColor?: number) => {
        if (session.status !== 'authenticated')
            return toast({
                title: "Login Required",
                description: "You need login to add items to cart",
                action: <ToastAction altText='Login' onClick={onToastLoginButtonClick} onTouchStart={onToastLoginButtonClick}>Login</ToastAction>,
                variant: 'destructive'
            })
        startTransition(async () => {
            const response = await addItemToCart(productID, customImgCtx, isCustomizable, qty, selectedSize, selectedColor)
            if (!response)
                toast({
                    title: "Error",
                    description: "Failed to add item to cart",
                    variant: 'destructive'
                })
            else {
                setCommonCtx(prev => {
                    if (qty !== response.quantity)                //find if the item is already in the cart
                    {

                        const itemIndex = prev.cartItems.findIndex(x => x.id === response.id)
                        if (itemIndex !== -1) {
                            const newCartItems = [...prev.cartItems]
                            newCartItems[itemIndex].quantity = response.quantity
                            return ({ ...prev, cartItems: newCartItems, cartDrawerOpen: true })
                        }
                    }

                    return ({
                        ...prev,
                        cartItems: [...prev.cartItems, response],
                        cartDrawerOpen: true
                    })
                })
            }
        })
    }

    const updateQty = async (itemID: number, qty: number) => {
        startTransition(async () => {
            const newQty = await updateCartItemQty(itemID, qty)
            if (!newQty)
                toast({
                    title: 'Error',
                    description: 'Failed to update quantity',
                    variant: 'destructive'
                })
            else {
                setCommonCtx(prev => ({ ...prev, cartItems: prev.cartItems.map(x => x.id === itemID ? { ...x, quantity: newQty } : x) }))
            }
        })
    }

    const removeItem = async (itemID: number) => {
        startTransition(async () => {
            const response = await removeCartItem(itemID)
            if (response) {
                setCommonCtx(prev => ({ ...prev, cartItems: prev.cartItems.filter(x => x.id !== itemID) }))
            } else {
                toast({
                    title: 'Error',
                    description: 'Failed to remove item from cart',
                    variant: 'destructive'
                })
            }
        })
    }

    const refresh = async () => {
        startTransition(async () => {
            const response = await getCartItems()
            if (response)
                setCommonCtx(prev => ({ ...prev, cartItems: response }))
            else
                toast({
                    title: 'Error',
                    description: 'Failed to get cart items',
                    variant: 'destructive'
                })
        })
    }


    const handleCheckout = () => {
        startTransition(async () => {
            const response = await cartCheckout(commonCtx.cartItems.map(x => x.id))

            if (!response)
                toast({
                    variant: 'destructive',
                    title: 'Error',
                    description: 'Failed to checkout, please try again!'
                })
            else {
                router.push('/checkout?id=' + response)
                setCommonCtx(prev => ({ ...prev, cartDrawerOpen: false }))
            }
        })
    }

    const openCart = () => {
        setCommonCtx(prev => ({ ...prev, cartDrawerOpen: true }))
    }

    const closeCart = () => {
        setCommonCtx(prev => ({ ...prev, cartDrawerOpen: false }))
    }

    return {
        cartItems: commonCtx.cartItems,
        loading,
        addToCart,
        updateQty,
        removeItem,
        refresh,
        handleCheckout,
        openCart,
        closeCart
    }
}
