'use client'
import { CartItemType } from '@/utils/APIRouteTypes'
import React, { createContext, SetStateAction, useState } from 'react'

type CommonContextType = {
    loginModalOpen: boolean,
    loginModalAction: 'login' | 'imageGen' | 'cod'
    cartDrawerOpen: boolean
    cartItems: CartItemType[]
}

const CommonContext = createContext<[CommonContextType, React.Dispatch<SetStateAction<CommonContextType>>] | null>(null)

export default function CommonContextProvider({ children, cartItems }: { children: React.ReactNode, cartItems: CartItemType[] }) {

    const [value, setValue] = useState<CommonContextType>({
        loginModalOpen: false,
        loginModalAction: 'login',
        cartDrawerOpen: false,
        cartItems: cartItems
    })

    return (
        <CommonContext.Provider value={[value, setValue]}>
            {children}
        </CommonContext.Provider>
    )
}


export const useCommonContext = () => {
    const context = React.useContext(CommonContext)
    if (context === null) {
        throw new Error('useCommonContext must be used within a CommonContextProvider')
    }
    return context
}